import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.autAdministration())?_c('div',{staticClass:"ma-2"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"50%"}},[_c(VList,{attrs:{"width":"100%"}},[_c(VSubheader,[_vm._v(_vm._s(_vm.dictionnaire[0]))]),_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.autorisations),function(uneAutorisation,a){return _c(VListItem,{key:a + 'A',attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.estAutorise(uneAutorisation)}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-left"},[_vm._v(_vm._s(uneAutorisation.affichage))])],1)],1)}),1)],1)],1),(_vm.uneAutorisation.sa_designation != '')?_c('div',{staticStyle:{"width":"50%"}},[_c('div',[_vm._v(_vm._s(_vm.uneAutorisation.affichage))]),_vm._l((_vm.utilisateurs),function(unUtilisateur,u){return _c(VCheckbox,{key:u + 'lesutilisateursAutorise',staticClass:"ma-0",attrs:{"label":unUtilisateur.ut_nom + ' ' + unUtilisateur.ut_prenom,"hide-details":""},on:{"click":function($event){return _vm.enregistrerAutoriser()}},model:{value:(unUtilisateur.autorise),callback:function ($$v) {_vm.$set(unUtilisateur, "autorise", $$v)},expression:"unUtilisateur.autorise"}})})],2):_vm._e()]),_c(VSnackbar,{attrs:{"top":""},model:{value:(_vm.snak_visible),callback:function ($$v) {_vm.snak_visible=$$v},expression:"snak_visible"}},[_vm._v(" "+_vm._s(_vm.snakbar_text)+" ")])],1):_c('div',[_c('NonAcces')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }