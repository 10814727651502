
import { variables } from "@/plugins/variables"
import { Autorisation } from "@/store/types"
import axios from "axios"
import Vue from "vue"
import NonAcces from "../components/NonAcces.vue"

export default Vue.extend({
  name: "Autorisation",
  components: { NonAcces },
  data() {
    return {
      unsubscribe: null as any,
      utilisateurs: [] as any[],
      autorisations: [] as Autorisation[],
      uneAutorisation: new Autorisation(),
      dictionnaire: [] as string[],
      snakbar_text: "",
      snak_visible: false
    }
  },
  methods: {
    initialiser() {
      axios
        .get(this.$store.state.ajaxurl + "Autorisation.php", {
          params: {
            action: "INITIALISER_AUTORISATION"
          }
        })
        .then(response => {
          this.autorisations = response.data.autorisations.map((a: Autorisation) => {
            return new Autorisation(a)
          })
          this.utilisateurs = response.data.utilisateurs.map((u: any) => {
            u["autorise"] = false
            return u
          })
          this.choixLangue(this.$store.state.langue)
        })
    },
    estAutorise(uneAutorisation: Autorisation) {
      this.uneAutorisation = uneAutorisation
      for (const unUtilisateur of this.utilisateurs) {
        unUtilisateur.autorise = false
      }
      for (const unAutorise of uneAutorisation.getAutorise()) {
        for (const unUtilisateur of this.utilisateurs) {
          if (unAutorise.ut_id + "" == unUtilisateur.ut_id + "") {
            unUtilisateur.autorise = true
          }
        }
      }
    },
    enregistrerAutoriser() {
      const uneAutorisation = this.autorisations.find((a: Autorisation) => a.getDesignation() == this.uneAutorisation.getDesignation())
      if (uneAutorisation !== undefined) {
        uneAutorisation.setAutorise([])
        for (const unUtilisateur of this.utilisateurs) {
          if (unUtilisateur.autorise == true) {
            uneAutorisation.getAutorise().push({ ut_id: unUtilisateur.ut_id })
          }
        }
        axios.post(this.$store.state.ajaxurl + "Autorisation.php", {
          action: "ENREGISTRER_AUTORISATION",
          uneAutorisation: uneAutorisation
        })
      }
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = ["AUTORISATIONS"]
          for (const uneAutorisation of this.autorisations) {
            switch (uneAutorisation.getDesignation()) {
              case "Administration":
                uneAutorisation.setAffichage("Administration")
                break
              case "Tranfert laitier":
                uneAutorisation.setAffichage("Transfert laitier")
                break
              case "Maintenance":
                uneAutorisation.setAffichage("Maintenance")
                break
              case "Gestion pneu":
                uneAutorisation.setAffichage("Gestion pneu")
                break
              case "Gestion machine":
                uneAutorisation.setAffichage("Gestion machine")
                break
              case "Fosse":
                uneAutorisation.setAffichage("Fosse")
                break
              case "Horametre":
                uneAutorisation.setAffichage("Horamètre")
                break
            }
          }
          break
        case "AL":
          this.dictionnaire = ["GENEHMIGUNGEN"]
          for (const uneAutorisation of this.autorisations) {
            switch (uneAutorisation.getDesignation()) {
              case "Administration":
                uneAutorisation.setAffichage("Verwaltung")
                break
              case "Tranfert laitier":
                uneAutorisation.setAffichage("Schlacken transport")
                break
              case "Maintenance":
                uneAutorisation.setAffichage("Instandhaltung")
                break
              case "Gestion pneu":
                uneAutorisation.setAffichage("Reifenmanagement")
                break
              case "Gestion machine":
                uneAutorisation.setAffichage("Maschinenmanagement")
                break
              case "Fosse":
                uneAutorisation.setAffichage("Grube")
                break
              case "Horametre":
                uneAutorisation.setAffichage("Betriebsstunden")
                break
            }
          }
          break
      }
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    autAdministration() {
      return variables.autorisation.includes("Administration")
    }
  },
  created() {
    this.initialiser()
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
